// Type declarations for Phantom wallet
declare global {
  interface Window {
    solana?: {
      isPhantom?: boolean;
      isConnected: boolean;
      connect(): Promise<{ publicKey: { toString(): string } }>;
      disconnect(): void;
    };
  }
}

interface Transaction {
  amount: number;
  timestamp: number;
  type: 'earn' | 'spend' | 'stake' | 'unstake' | 'reward';
  description: string;
  location?: {
    lat: number;
    lng: number;
  };
}

interface RealityCoinStore {
  balance: number;
  stakedBalance: number;
  isLoading: boolean;
  error: string;
  transactions: Transaction[];
  
  checkBalance(): Promise<number>;
  earnCoins(amount: number, reason: string): Promise<void>;
  spendCoins(amount: number, reason: string): Promise<boolean>;
  stakeCoins(amount: number, location: { lat: number; lng: number }, pppProof: string): Promise<void>;
  unstakeCoins(amount: number, location: { lat: number; lng: number }): Promise<void>;
  claimRewards(location: { lat: number; lng: number }): Promise<number>;
  verifyPPP(location: { lat: number; lng: number }): Promise<void>;
  dispatchUpdate(): void;
}

// Helper function to safely parse JSON
function safeJSONParse<T>(data: string, fallback: T): T {
  if (!data) return fallback;
  try {
    return JSON.parse(data) as T;
  } catch (e) {
    console.error('JSON parse error:', e);
    return fallback;
  }
}

// Helper function to safely stringify JSON
function safeJSONStringify(data: unknown): string {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.error('JSON stringify error:', e);
    return '{}';
  }
}

export const realityCoinStore: RealityCoinStore = {
  balance: 0,
  stakedBalance: 0,
  isLoading: false,
  error: '',
  transactions: [],

  async checkBalance() {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual balance check from blockchain
      await new Promise(resolve => setTimeout(resolve, 1000));
      return this.balance;
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async earnCoins(amount: number, reason: string) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual blockchain transaction
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      this.balance += amount;
      this.transactions.push({
        amount,
        timestamp: Date.now(),
        type: 'earn',
        description: reason
      });

      this.dispatchUpdate();
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async spendCoins(amount: number, reason: string) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    if (amount > this.balance) {
      this.error = 'Insufficient balance';
      return false;
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual blockchain transaction
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      this.balance -= amount;
      this.transactions.push({
        amount,
        timestamp: Date.now(),
        type: 'spend',
        description: reason
      });

      this.dispatchUpdate();
      return true;
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async stakeCoins(amount: number, location: { lat: number; lng: number }, pppProof: string) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    if (amount > this.balance) {
      throw new Error('Insufficient balance for staking');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual blockchain staking transaction
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      this.balance -= amount;
      this.stakedBalance += amount;
      this.transactions.push({
        amount,
        timestamp: Date.now(),
        type: 'stake',
        description: `Staked at location (${location.lat}, ${location.lng})`,
        location
      });

      this.dispatchUpdate();
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async unstakeCoins(amount: number, location: { lat: number; lng: number }) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    if (amount > this.stakedBalance) {
      throw new Error('Insufficient staked balance');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual blockchain unstaking transaction
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      this.stakedBalance -= amount;
      this.balance += amount;
      this.transactions.push({
        amount,
        timestamp: Date.now(),
        type: 'unstake',
        description: `Unstaked from location (${location.lat}, ${location.lng})`,
        location
      });

      this.dispatchUpdate();
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async claimRewards(location: { lat: number; lng: number }) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual blockchain reward claiming
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Mock reward amount - in production this would come from the blockchain
      const rewardAmount = 0.001 * this.stakedBalance;
      
      this.balance += rewardAmount;
      this.transactions.push({
        amount: rewardAmount,
        timestamp: Date.now(),
        type: 'reward',
        description: `Claimed rewards from location (${location.lat}, ${location.lng})`,
        location
      });

      this.dispatchUpdate();
      return rewardAmount;
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  async verifyPPP(location: { lat: number; lng: number }) {
    if (!window.solana?.isConnected) {
      throw new Error('Wallet not connected');
    }

    this.isLoading = true;
    this.error = '';

    try {
      // TODO: Implement actual PPP verification
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // In production, this would verify the user's physical presence
      // For now, we just update the verification timestamp
    } catch (error) {
      this.error = error instanceof Error ? error.message : 'Unknown error';
      throw error;
    } finally {
      this.isLoading = false;
    }
  },

  dispatchUpdate() {
    const eventDetail = safeJSONStringify({
      balance: this.balance,
      stakedBalance: this.stakedBalance
    });
    window.dispatchEvent(new CustomEvent('reality-coins-updated', {
      detail: safeJSONParse(eventDetail, {
        balance: this.balance,
        stakedBalance: this.stakedBalance
      })
    }));
  }
};

export default realityCoinStore;
