export const seaSirensPage = () => ({
    cseasCount: 0,

    init() {
        this.initModelViewer();
        
        // Initialize CSEAS count from localStorage
        this.cseasCount = parseInt(localStorage.getItem('cseasCount')) || 0;
        
        // Watch for CSEAS count changes
        this.$watch('cseasCount', value => {
            localStorage.setItem('cseasCount', value);
        });
    },

    async initModelViewer() {
        // Wait for model-viewer to be ready
        const modelViewer = document.querySelector('model-viewer');
        if (!modelViewer) {
            console.error('Model viewer element not found');
            return;
        }

        // Debug model loading
        modelViewer.addEventListener('error', (error) => {
            console.error('Model viewer error:', error);
        });

        modelViewer.addEventListener('load', () => {
            console.log('Model loaded successfully');
        });

        // Handle model visibility for CSEAS counting
        modelViewer.addEventListener('model-visibility', (e) => {
            if (e.detail.visible) this.cseasCount++;
        });
    }
});
