// Initialize everything when DOM is ready
export function initializeActivatePage() {
  console.log('Initializing activate page...');

  const programNfcBtn = document.getElementById('programNfcBtn');

  // NFC button handler
  if (programNfcBtn) {
    programNfcBtn.addEventListener('click', async () => {
      if (!('NDEFReader' in window)) {
        document.getElementById('toast-container').innerHTML = `
          <div class='alert alert-error'>
            <span>NFC is not supported on this device</span>
          </div>
        `;
        setTimeout(() => {
          document.getElementById('toast-container').innerHTML = '';
        }, 3000);
        return;
      }

      try {
        const ndef = new NDEFReader();
        const shortLinkEl = document.getElementById('shortLink');
        const shortLink = shortLinkEl.textContent.trim();
        const fullUrl = `https://cseas.fun/t/${shortLink}`;

        document.getElementById('toast-container').innerHTML = `
          <div class='alert alert-info'>
            <span>Ready to write. Hold your NFC tag near the device...</span>
          </div>
        `;

        // Direct write without scanning first
        await ndef.write({
          records: [{
            recordType: "url",
            data: fullUrl
          }]
        });
        
        document.getElementById('toast-container').innerHTML = `
          <div class='alert alert-success'>
            <span>NFC Tag successfully programmed!</span>
          </div>
        `;
        setTimeout(() => {
          document.getElementById('toast-container').innerHTML = '';
        }, 3000);
      } catch (error) {
        let errorMessage = 'Error writing to NFC tag: ';
        
        switch(error.name) {
          case 'NotReadableError':
            errorMessage += 'The tag could not be read.';
            break;
          case 'NotWritableError':
            errorMessage += 'The tag is read-only.';
            break;
          case 'NetworkError':
            errorMessage += 'Lost connection to the NFC tag.';
            break;
          case 'NotSupportedError':
            errorMessage += 'The tag type is not supported.';
            break;
          case 'SecurityError':
            errorMessage += 'NFC operation is not allowed.';
            break;
          case 'NotAllowedError':
            errorMessage += 'NFC permission denied. Please enable NFC in your device settings.';
            break;
          default:
            errorMessage += error.message || 'Unknown error occurred.';
        }
        
        document.getElementById('toast-container').innerHTML = `
          <div class='alert alert-error'>
            <span>${errorMessage}</span>
          </div>
        `;
        setTimeout(() => {
          document.getElementById('toast-container').innerHTML = '';
        }, 3000);
      }
    });
  }

  console.log('Activate page initialization complete');
}
