export const joPage = () => ({
    currentImageIndex: 0,
    images: ['/assets/images/jo/dosomething.jpg'],
    cseasCount: 0,

    init() {
        this.initModelViewer();
        this.startImageCycle();
        
        // Initialize CSEAS count from localStorage
        this.cseasCount = parseInt(localStorage.getItem('cseasCount')) || 0;
        
        // Watch for CSEAS count changes
        this.$watch('cseasCount', value => {
            localStorage.setItem('cseasCount', value);
        });
    },

    async initModelViewer() {
        // Wait for model-viewer to be ready
        const modelViewer = document.querySelector('model-viewer');
        if (!modelViewer) {
            console.error('Model viewer element not found');
            return;
        }

        // Debug model loading
        modelViewer.addEventListener('error', (error) => {
            console.error('Model viewer error:', error);
        });

        modelViewer.addEventListener('load', async () => {
            console.log('Model loaded, materials:', modelViewer.model.materials);
            
            // Wait a moment for materials to be fully loaded
            setTimeout(async () => {
                const materials = Array.from(modelViewer.model.materials);
                console.log('Available materials:', materials.map(m => m.name));
                
                // Try to find the material for the frame's display area
                const displayMaterial = materials[0]; // Use first material for now
                
                if (displayMaterial) {
                    console.log('Using material:', displayMaterial.name);
                    await this.updateModelTexture(this.images[this.currentImageIndex], displayMaterial);
                } else {
                    console.error('No suitable material found for texture');
                }
            }, 1000);
        });

        // Handle model visibility for CSEAS counting
        modelViewer.addEventListener('model-visibility', (e) => {
            if (e.detail.visible) this.cseasCount++;
        });
    },

    startImageCycle() {
        setInterval(async () => {
            this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
            const modelViewer = document.querySelector('model-viewer');
            if (!modelViewer || !modelViewer.model) return;

            const materials = Array.from(modelViewer.model.materials);
            if (materials.length > 0) {
                await this.updateModelTexture(this.images[this.currentImageIndex], materials[0]);
            }
        }, 5000);
    },

    async updateModelTexture(imagePath, material) {
        const modelViewer = document.querySelector('model-viewer');
        if (!modelViewer || !modelViewer.model) {
            console.error('Model viewer or model not available');
            return;
        }

        try {
            console.log('Creating texture for:', imagePath);
            const texture = await modelViewer.createTexture(imagePath);
            console.log('Texture created successfully');

            if (material && material.pbrMetallicRoughness) {
                // Update the material properties
                material.pbrMetallicRoughness.baseColorTexture = {
                    texture: texture
                };
                material.pbrMetallicRoughness.metallicFactor = 0;
                material.pbrMetallicRoughness.roughnessFactor = 1;
                
                // Force a render update
                modelViewer.requestUpdate();
                console.log('Texture applied successfully');
            } else {
                console.error('Material or pbrMetallicRoughness not available');
            }
        } catch (error) {
            console.error('Error updating texture:', error);
        }
    }
});
