import '@google/model-viewer';
import './alpine.js';  // Import Alpine initialization first
import { initializeActivatePage } from './activate.js';
import { bunnyPage } from './bunnyPage.js';
import { seaSirensPage } from './seaSirensPage.js';

// Initialize components
document.addEventListener('alpine:init', () => {
    Alpine.data('bunnyPage', bunnyPage);
    Alpine.data('seaSirensPage', seaSirensPage);

    // Initialize buoy data component
    Alpine.data('buoyData', () => ({
        waveHeight: 'Loading...',
        waterTemp: 'Loading...',
        lastUpdate: '',
        isLoading: true,
        error: null,

        init() {
            this.fetchData();
            // Update every 5 minutes
            setInterval(() => this.fetchData(), 5 * 60 * 1000);
        },

        async fetchData() {
            try {
                this.isLoading = true;
                this.error = null;
                
                const response = await fetch('/api/buoy-data');
                const data = await response.json();
                
                if (!response.ok) {
                    throw new Error(data.error || 'Failed to fetch buoy data');
                }

                if (data.error) {
                    throw new Error(data.error);
                }

                // Validate the data
                if (!data.waveHeight || !data.waterTemp) {
                    throw new Error('Missing buoy measurements');
                }

                // Convert string values to numbers and format
                const waveHeight = parseFloat(data.waveHeight);
                const waterTemp = parseFloat(data.waterTemp);

                if (isNaN(waveHeight) || isNaN(waterTemp)) {
                    throw new Error('Invalid buoy measurements');
                }

                this.waveHeight = `${waveHeight.toFixed(1)}ft`;
                this.waterTemp = `${waterTemp.toFixed(1)}°F`;
                
                // Format timestamp
                const timestamp = new Date(data.timestamp);
                this.lastUpdate = `Last updated: ${timestamp.toLocaleTimeString()}`;
                
                // Clear any previous errors
                this.error = null;
            } catch (error) {
                console.error('Error fetching buoy data:', error);
                this.error = error.message || 'Failed to fetch buoy data';
                this.waveHeight = 'Error';
                this.waterTemp = 'Error';
            } finally {
                this.isLoading = false;
            }
        }
    }));
});

// Initialize activate page if we're on that page
document.addEventListener('DOMContentLoaded', () => {
    // Check if we're on the activate page by looking for its form
    if (document.getElementById('realityTagForm')) {
        initializeActivatePage();
    }

    // Existing model-viewer code
    const modelViewer = document.querySelector('#jo-model');
    if (modelViewer) {
        modelViewer.addEventListener('load', async () => {
            const material = modelViewer.model.materials[0];
            if (material && material.pbrMetallicRoughness.baseColorTexture) {
                // Set initial texture
                const initialImage = '/assets/images/jo/dosomething.jpg';
                try {
                    const texture = await modelViewer.createTexture(initialImage);
                    material.pbrMetallicRoughness.baseColorTexture.texture = texture;
                } catch (error) {
                    console.error('Error loading texture:', error);
                }
            }
        });
    }
});

// Function to update model texture
window.updateModelTexture = async (modelViewer, imagePath) => {
    if (modelViewer && modelViewer.model) {
        const material = modelViewer.model.materials[0];
        if (material && material.pbrMetallicRoughness.baseColorTexture) {
            try {
                const texture = await modelViewer.createTexture(imagePath);
                material.pbrMetallicRoughness.baseColorTexture.texture = texture;
            } catch (error) {
                console.error('Error updating texture:', error);
            }
        }
    }
};
